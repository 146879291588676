$(document).ready(function() {
	if(!document.querySelector('.notices-list-wrap')) return false;

	var $notice = $('.notices-list-wrap .notices-list.vertical-carousel'),
		$noticeWrap = $('.notices-list-wrap');
	$notice.slick({
		infinite: false, //LGEUS-683
		vertical: true,
		autoplay: true,
		touchMove: false,
		autoplaySpeed: 5000,
		appendArrows: $notice.next('.carousel-btn-wrap'),
		prevArrow: carouselOptions.squarePrev, // common.js variable
		nextArrow: carouselOptions.squareNext // common.js variable
		// add custom pause btn
	});
	var $pausePosition = $notice[0].slick.$prevArrow;
	$pausePosition.after('<button class="slick-pause type-square" aria-label="Pause" type="button"><span class="visually-hidden">Pause</span></button>');
	$pausePosition.after('<button class="slick-play type-square" aria-label="Play" type="button"><span class="visually-hidden">Play</span></button>');

	var $pause = $notice[0].slick.options.appendArrows.find('.slick-pause'),
		$play = $notice[0].slick.options.appendArrows.find('.slick-play');

	$notice[0].slick.options.appendArrows.find('.slick-pause').on({
		click: function(){
			if(!$noticeWrap.hasClass('paused')) {
				$notice.slick('slickPause');
				$noticeWrap.addClass('paused');
				$play.focus();
			}
		}
	});
	$notice[0].slick.options.appendArrows.find('.slick-play').on({
		click: function(){
			if($noticeWrap.hasClass('paused')) {
				$notice.slick('slickPlay');
				$noticeWrap.removeClass('paused');
				$pause.focus();
			}
		}
	});
});